import React from "react";
import {View, Text} from "react-native";
import DashboardLayout from "../components/layouts/DashboardLayout";
import DashboardError from "../components/DashboardError";
import {tailwind} from "@ch/foundations/styles";
import {gql, useQuery} from "@apollo/client";
import {EnterpriseDashboardHomeQuery} from "./__generated__/index.pagetypes";
import {BasicStatTile, TestingStatsTile, VaccinationStatsTile} from "../components";
import {Heading, Paragraph, SkeletonBox} from "@ch/foundations/ui/";
import {isDefined} from "@ch/foundations/utils/truthy";
import {Head} from "../lib/Head";
import OrgTypeBoundary from "src/components/OrgTypeBoundary";
import {OrganizationType} from "src/types/__generated__/graphql";
import TreatmentAuthorizationTile from "src/components/occ-health-dashboard/TreatmentAuthorizationTile";
import ContactUsTile from "src/components/occ-health-dashboard/ContactUsTile";

export const HomeQuery = gql`
  query EnterpriseDashboardHome {
    me {
      ... on OrganizationAdminUser {
        id
        firstName
        organization {
          id
          name
          groups {
            totalCount
          }
          members {
            totalCount
          }
          enrolled: members(status: ENROLLED) {
            totalCount
          }
        }
      }
    }
  }
`;

export function BusinessDashboardHome() {
  const {data, loading, error} = useQuery<EnterpriseDashboardHomeQuery>(HomeQuery);

  if (loading) {
    return (
      <View style={tailwind("flex-col h-full w-full px-6")}>
        <SkeletonBox
          width={300}
          height={34}
          style={[tailwind("mb-2 mt-14"), {paddingBottom: "2px"}]}
        />
        <SkeletonBox width={540} height={20} />
        <View style={tailwind("flex-row w-full mt-10")}>
          <BasicStatTile style={tailwind("mr-3")} loading />
          <BasicStatTile style={tailwind("mx-3")} loading />
          <BasicStatTile style={tailwind("ml-3")} loading />
        </View>
      </View>
    );
  }

  if (error || !data || !data.me) {
    // TODO -- report error
    return <DashboardError type="network" />;
  }

  switch (data.me.__typename) {
    case "OrganizationAdminUser": {
      const {name: orgName, groups, members, enrolled} = data.me.organization;
      return (
        <View accessible style={tailwind("flex-col h-full w-full px-6")}>
          <View style={[tailwind("mb-2 mt-14")]}>
            <Heading level={5} accessibilityLevel={1}>
              Welcome back,{" "}
              <View style={[tailwind("w-max overflow-hidden"), {paddingBottom: "2px"}]}>
                <Text>{data.me.firstName}.</Text>
                <View
                  style={[
                    tailwind("absolute left-0 bottom-0 w-full h-1"),
                    {
                      backgroundImage: "url('/static/images/underline.svg')",
                    },
                  ]}
                />
              </View>
            </Heading>
          </View>
          <Paragraph level={2} style={tailwind("text-gray-400")} accessibilityRole="heading">
            Here’s a snapshot of the {orgName} Organization with Carbon Health.
          </Paragraph>
          {/* stats overview row */}
          <View style={tailwind("flex-row w-full mt-10")}>
            {groups && isDefined(groups.totalCount) && (
              <BasicStatTile
                style={tailwind("mr-3")}
                image="groups"
                heading="Groups"
                value={groups.totalCount.toLocaleString() ?? ""}
              />
            )}
            {members && isDefined(members.totalCount) && (
              <BasicStatTile
                style={tailwind("mx-3")}
                image="organization-members"
                heading="Organization Members"
                value={members.totalCount.toLocaleString()}
              />
            )}
            {enrolled && isDefined(enrolled.totalCount) && (
              <BasicStatTile
                style={tailwind("ml-3")}
                image="enrolled-members"
                heading="Enrolled Members"
                value={
                  enrolled && members?.totalCount
                    ? Math.floor(
                        (enrolled.totalCount / members.totalCount) * 100,
                      ).toLocaleString() + "%"
                    : enrolled?.totalCount.toLocaleString()
                }
              />
            )}
          </View>
          {/* Vaccination and testing stats row */}
          <OrgTypeBoundary
            showFor={[
              OrganizationType.Enterprise,
              OrganizationType.University,
              OrganizationType.Program,
              null,
            ]}
          >
            <View style={tailwind("flex-row w-full mt-6")}>
              <View style={tailwind("w-1/2 pr-3")}>
                <VaccinationStatsTile />
              </View>
              <View style={tailwind("w-1/2 pl-3")}>
                <TestingStatsTile />
              </View>
            </View>
          </OrgTypeBoundary>
          <OrgTypeBoundary showFor={[OrganizationType.OccHealth]}>
            <View style={tailwind("flex-row w-full mt-6")}>
              <View style={tailwind("w-1/3 pr-3")}>
                <TreatmentAuthorizationTile />
              </View>
              <View style={tailwind("w-2/3 pl-3")}>
                <ContactUsTile />
              </View>
            </View>
          </OrgTypeBoundary>
        </View>
      );
    }

    case "AuthenticationError":
    default: {
      return <DashboardError type="auth" />;
    }
  }
}

export default function BusinessDashboardHomePage() {
  return (
    <DashboardLayout>
      <Head>
        <title>Carbon Enterprise | Home</title>
      </Head>
      <BusinessDashboardHome />
    </DashboardLayout>
  );
}
