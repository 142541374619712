import {View, StyleProp, ViewStyle, Text} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Icon, Paragraph, Tile} from "../../lib/foundations/ui";
import {Image} from "../../lib/Image";
import TreatmentAuthForm from "../../../public/static/images/taf-thumbnail.jpg";
import {Link} from "../../lib/Link";

type TreatmentAuthorizationTileProps = {
  style?: StyleProp<ViewStyle>;
};

export default function TreatmentAuthorizationTile(props: TreatmentAuthorizationTileProps) {
  return (
    <Tile
      testID="treatment-authorization"
      style={[tailwind("p-6 flex-col"), {minWidth: "300px"}, props.style]}
    >
      <View style={tailwind("w-full")}>
        <View style={[tailwind("border border-gray-50")]}>
          <Image src={TreatmentAuthForm} alt="Treatment Authorization Form" />
        </View>
        <View style={tailwind("pt-6")}>
          <Paragraph>Treatment Authorization Form </Paragraph>
          <Paragraph level={2} style={tailwind("text-gray-400 pt-2")}>
            Print and share this for treatment.
          </Paragraph>
          <View style={tailwind("items-start pt-6")}>
            <Link
              href="https://crbn.app.link/e/preauthorization-form"
              accessibilityLabel="Open Treatment Authorization Form"
              external
            >
              <View
                style={[
                  tailwind("flex-row items-center justify-center rounded-full bg-gray-100"),
                  {paddingHorizontal: 20, paddingVertical: 10},
                ]}
              >
                <Text style={tailwind("text-gray-800 font-semibold text-sm")}>Print PDF</Text>
                <View style={tailwind("ml-2")}>
                  <Icon name="diagonal-arrow" size={10} />
                </View>
              </View>
            </Link>
          </View>
        </View>
      </View>
    </Tile>
  );
}
