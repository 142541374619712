import {
  Paragraph,
  ProgressBar,
  Tile,
  Caption,
  Rule,
  SkeletonBox,
  LoadingSpinner,
  Icon,
  CarbonModal,
} from "@ch/foundations/ui";
import {ColorValue, StyleProp, TouchableOpacity, View, ViewStyle} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import Colors from "@ch/foundations/ui/colors.json";
import {useQuery, gql} from "@apollo/client";
import {
  VaccinationStatsQuery,
  VaccinationStatsQueryVariables,
  OrganizationVaccinationStatsFragment,
} from "./__generated__/VaccinationStatsTiletypes";
import React, {useState} from "react";
import {isDefined} from "../../lib/foundations/utils/truthy";
import VaccinationStatsInfo from "./VaccinationStatsInfo";
type VaccinationStatsTileProps = {
  style?: StyleProp<ViewStyle>;
};

export const VACCINATION_STATS_QUERY = gql`
  query VaccinationStats {
    me {
      ... on OrganizationAdminUser {
        organization {
          id
          ...OrganizationVaccinationStats
        }
      }
    }
  }
  fragment OrganizationVaccinationStats on Organization {
    enrolledMembers: members(status: ENROLLED, first: 0) {
      totalCount
    }
    covidVaccinationStats {
      partiallyVaccinatedCount
      fullyVaccinatedCount
      vaccinatedPlusBoosterCount
      medicalExemptCount
      religiousExemptCount
      unvaccinatedCount
    }
  }
`;

export default function VaccinationStatsTile(props: VaccinationStatsTileProps) {
  const {loading, data, error} = useQuery<VaccinationStatsQuery, VaccinationStatsQueryVariables>(
    VACCINATION_STATS_QUERY,
  );
  const [modalVisible, setModalVisible] = useState(false);

  if (loading)
    return (
      <Tile style={[tailwind("p-6 flex-col"), {minWidth: "300px"}, props.style]}>
        <View style={tailwind("w-full")}>
          {/* Top section */}
          <View style={tailwind("flex-row justify-between")}>
            {/* Top Left */}
            <View>
              <Paragraph>COVID-19 Vaccinations</Paragraph>
              <SkeletonBox style={[tailwind("mt-2"), {width: 223, height: 20}]} />
            </View>
          </View>
        </View>
        <View style={[tailwind("w-full justify-center items-center"), {height: 228}]}>
          <LoadingSpinner />
        </View>
      </Tile>
    );

  // if there is an error, just don't render the tile. We don't need to break
  // the whole page for this
  if (error || !data || !data.me) {
    return null;
  }
  switch (data.me.__typename) {
    case "OrganizationAdminUser": {
      const {enrolledMembers, covidVaccinationStats} = data.me.organization;
      // If we don't have this, just don't render the tile at all.

      if (!enrolledMembers || !isDefined(enrolledMembers.totalCount)) return null;
      const enrolledMembersCount: number = enrolledMembers.totalCount;
      const rowData = mapStatsToRows({covidVaccinationStats});

      return rowData ? (
        <>
          {/* Info modal -- closed by default */}
          <CarbonModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            headerTitle="COVID-19 Vaccination Status"
          >
            <VaccinationStatsInfo />
          </CarbonModal>

          <Tile
            testID="vaccination-stats"
            style={[tailwind("p-6 flex-col"), {minWidth: "300px"}, props.style]}
          >
            <View style={tailwind("w-full")}>
              {/* Top section */}
              <View style={tailwind("flex-row justify-between")}>
                {/* Top Left */}
                <View>
                  <Paragraph>COVID-19 Vaccinations</Paragraph>
                  <Paragraph level={2} style={tailwind("mt-2 text-gray-400")}>
                    Based on{" "}
                    <Paragraph level={2} weight="bold" style={tailwind("text-gray-400")}>
                      {enrolledMembersCount}
                    </Paragraph>{" "}
                    enrolled members
                  </Paragraph>
                </View>
                {/* Top Right */}
                <TouchableOpacity
                  testID="open-vaccinations-info"
                  accessibilityRole="button"
                  accessibilityLabel="info about vaccination stats"
                  onPress={() => {
                    setModalVisible(true);
                  }}
                  style={tailwind("self-start")}
                >
                  <Icon name="info" size={20} color={Colors.gray[200]} />
                </TouchableOpacity>
              </View>
              {/* Bottom (content) section */}
              <View style={tailwind("flex-col mt-10")}>
                {rowData.map(({label, value, color}, i) =>
                  i === 3 ? (
                    <React.Fragment key={label}>
                      <Rule style={tailwind("my-5")} />
                      <Row
                        label={label}
                        value={value}
                        maxValue={enrolledMembersCount}
                        color={color}
                      />
                    </React.Fragment>
                  ) : (
                    <Row
                      key={label}
                      style={tailwind("mt-3")}
                      maxValue={enrolledMembersCount}
                      label={label}
                      value={value}
                      color={color}
                    />
                  ),
                )}
              </View>
            </View>
          </Tile>
        </>
      ) : null;
    }
    case "AuthenticationError": {
      throw new Error("AuthenticationError");
    }
  }
  return null;
}

function Row({
  label,
  value,
  color,
  style,
  maxValue,
}: {
  label: string;
  value: number;
  color: ColorValue;
  style?: StyleProp<ViewStyle>;
  maxValue: number;
}) {
  return typeof value !== "number" ? null : (
    <View style={[tailwind("flex-row h-5 justify-between items-center"), style]}>
      {/* This width is a hard-coded width based on the longest label that we
      CURRENTLY have. If we change labels, we'll need to change this */}
      <Caption style={{width: 135}}>{label}</Caption>
      <View style={[tailwind("flex-1 px-4"), {height: 10}]}>
        <ProgressBar
          maxValue={maxValue}
          style={tailwind("flex-1")}
          segments={[{label, value, color}]}
        />
      </View>
      {/* This width is hard-coded based on the "100%" value, which
      should always be the longest possible value here */}
      <Paragraph style={tailwind("w-8")} level={2} weight="bold">
        {Math.round((value / maxValue) * 100)}%
      </Paragraph>
    </View>
  );
}

export function mapStatsToRows({covidVaccinationStats}: OrganizationVaccinationStatsFragment) {
  // if we have no stats data, we can't show progress bars.
  if (!covidVaccinationStats) return null;

  const {
    vaccinatedPlusBoosterCount,
    fullyVaccinatedCount,
    partiallyVaccinatedCount,
    medicalExemptCount,
    religiousExemptCount,
    unvaccinatedCount,
  } = covidVaccinationStats;

  return [
    {
      label: "Partially Vaccinated",
      value: partiallyVaccinatedCount,
      color: Colors.blue.darker,
    },
    {
      label: "Fully Vaccinated",
      value: fullyVaccinatedCount,
      color: Colors.blue.dark,
    },
    {
      label: "Vaccinated + Booster",
      value: vaccinatedPlusBoosterCount,
      color: Colors.blue.light,
    },
    {label: "Not Vaccinated", value: unvaccinatedCount, color: Colors.purple.darker},
    {
      label: "Medical Exemption",
      value: medicalExemptCount,
      color: Colors.purple.dark,
    },
    {
      label: "Religious Exemption",
      value: religiousExemptCount,
      color: Colors.purple.DEFAULT,
    },
  ];
}
