import {ColorValue, StyleProp, View, ViewStyle} from "react-native";
import Colors from "@ch/foundations/ui/colors.json";
type RuleProps = {
  color?: ColorValue;
  style?: StyleProp<ViewStyle>;
};
export default function Rule(props: RuleProps) {
  return (
    <View
      style={[
        {
          width: "100%",
          height: 1,
          borderBottomColor: props.color || Colors.gray[100],
          borderBottomWidth: 1,
        },
        props.style,
      ]}
      accessibilityRole="separator"
    />
  );
}
