import {useRef, useCallback} from "react";
import useEventListener from "@use-it/event-listener";
import {Modal, StyleProp, TouchableOpacity, View, ViewStyle} from "react-native";

import {tailwind} from "@ch/foundations/styles";
import {Icon, Subtitle} from "@ch/foundations/ui";

type CarbonModalProps = {
  style?: StyleProp<ViewStyle>;
  width?: number;
  visible: boolean;
  onClose: () => void;
  headerTitle: string;
  headerTitleDescription?: string;
  children?: React.ReactNode;
};

export default function CarbonModal({
  style,
  width,
  visible,
  onClose,
  headerTitle,
  headerTitleDescription,
  children,
}: CarbonModalProps) {
  const modalRef = useRef<View>(null);

  // Close modal when modal is clicked out of
  const clickListener = useCallback(
    (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (modalRef.current && !(modalRef.current as any).contains(e.target)) onClose();
    },
    [onClose, modalRef],
  );
  useEventListener("click", clickListener, document, {capture: true});

  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={tailwind("flex-1 bg-modal-opacity")}>
        <View
          ref={modalRef}
          style={[
            tailwind("bg-white rounded-2xl max-h-full"),
            {
              margin: "auto",
              width: width || 610,
              shadowOpacity: 0.35,
              shadowRadius: 20,
              elevation: 20,
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 6},
            },
            style,
          ]}
        >
          <View
            style={tailwind("m-6 pl-3 flex-row items-center justify-between")}
            accessibilityLiveRegion="polite"
          >
            <Subtitle level={2} weight="bold">
              {headerTitle}
              {!!headerTitleDescription && (
                <Subtitle level={2}> | {headerTitleDescription}</Subtitle>
              )}
            </Subtitle>
            <TouchableOpacity onPress={onClose} testID="close-modal">
              <Icon name="close" size={36} />
            </TouchableOpacity>
          </View>
          <View style={tailwind("mx-6 mb-6 bg-white")}>{children}</View>
        </View>
      </View>
    </Modal>
  );
}
