import React from "react";
import {StyleProp, Text} from "react-native";
import {StyleSheet, TextStyle} from "react-native";
import Colors from "./colors.json";

type CaptionProps = {
  level?: 1 | 2;
  weight?: "light" | "bold" | "normal";
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityRole?: "heading" | "button" | "label" | "link" | "listitem";
  testID?: string;
};

export default function Caption({
  level = 1,
  weight = "normal",
  style,
  children,
  accessibilityRole,
  testID,
}: CaptionProps) {
  return (
    <Text
      testID={testID}
      accessibilityRole={accessibilityRole}
      style={[captionStyles[level], captionStyles[weight], style]}
    >
      {children}
    </Text>
  );
}

const captionStyles = StyleSheet.create({
  bold: {fontFamily: "Inter-semibold"},
  light: {fontFamily: "Inter-light"},
  normal: {fontFamily: "Inter-regular"},
  1: {
    fontSize: 13,
    lineHeight: 18,
    color: Colors.gray[800],
  },
  2: {
    fontSize: 12,
    lineHeight: 22,
    color: Colors.gray[800],
  },
});
