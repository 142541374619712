import {View, StyleProp, ViewStyle, Text} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Icon, Paragraph, Tile} from "../../lib/foundations/ui";
import {Image} from "../../lib/Image";
import Craft from "../../../public/static/images/Craft.png";
import {Link} from "../../lib/Link";

type ContactUsTileProps = {
  style?: StyleProp<ViewStyle>;
};

export default function ContactUsTile(props: ContactUsTileProps) {
  return (
    <Tile
      testID="contact-us"
      style={[tailwind("p-6 flex-col justify-center"), {minWidth: "300px"}, props.style]}
    >
      <View style={tailwind("flex-row items-center")}>
        <View style={tailwind("w-1/2")}>
          <Image src={Craft} alt="People Planting a Tree" objectFit="fill" />
        </View>
        <View style={tailwind("w-1/2 ml-6 pr-6")}>
          <Paragraph style={tailwind(" text-gray-400")}>
            We’re working on creating new features just for you.
          </Paragraph>
          <Paragraph style={tailwind("pt-4 text-gray-400")}>
            Have thoughts on what would be useful?
          </Paragraph>
          <View style={tailwind("items-start pt-3")}>
            <Link
              href="https://crbn.app.link/e/feedback"
              accessibilityLabel="Open contact us button"
              external
            >
              <View
                style={[
                  tailwind("flex-row items-center justify-center rounded-full bg-gray-100"),
                  {paddingHorizontal: 20, paddingVertical: 10},
                ]}
              >
                <Text style={tailwind("text-gray-800 font-semibold text-sm")}>Contact us</Text>
                <View style={tailwind("ml-2")}>
                  <Icon name="diagonal-arrow" size={10} />
                </View>
              </View>
            </Link>
          </View>
        </View>
      </View>
    </Tile>
  );
}
