import React from "react";
import {StyleProp, Text} from "react-native";
import {StyleSheet, TextStyle} from "react-native";
import Colors from "./colors.json";

type HeadingProps = {
  level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  weight?: "light" | "bold" | "normal";
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityLevel?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  accessibilityRole?: "heading" | "button" | "label" | "link" | "listitem";
};

export default function Heading({
  level,
  weight = "normal",
  style,
  children,
  accessibilityLevel: overrideaccessibilityLevel,
  accessibilityRole = "heading",
}: HeadingProps) {
  // use the accessibilityLevel prop or the default for the given level
  // level 0 defaults to a heading level 1
  const accessibilityLevel = overrideaccessibilityLevel || level === 0 ? 1 : level;

  return (
    <Text
      accessibilityLevel={accessibilityRole === "heading" ? accessibilityLevel : undefined}
      accessibilityRole={accessibilityRole}
      style={[headingStyles[level], headingStyles[weight], style]}
    >
      {children}
    </Text>
  );
}

const baseHeadingStyles: TextStyle = {
  color: Colors.gray[800],
};

const headingStyles = StyleSheet.create({
  bold: {fontFamily: "Inter-semibold"},
  light: {fontFamily: "Inter-light"},
  normal: {fontFamily: "Inter-regular"},
  0: {
    ...baseHeadingStyles,
    fontSize: 72,
    lineHeight: 82,
  },
  1: {
    ...baseHeadingStyles,
    fontSize: 60,
    lineHeight: 68,
  },
  2: {
    ...baseHeadingStyles,
    fontSize: 48,
    lineHeight: 56,
  },
  3: {
    ...baseHeadingStyles,
    fontSize: 40,
    lineHeight: 48,
  },
  4: {
    ...baseHeadingStyles,
    fontSize: 32,
    lineHeight: 40,
  },
  5: {
    ...baseHeadingStyles,
    fontSize: 28,
    lineHeight: 34,
  },
  6: {
    ...baseHeadingStyles,
    fontSize: 24,
    lineHeight: 32,
  },
  7: {
    ...baseHeadingStyles,
    fontSize: 20,
    lineHeight: 28,
  },
});
