import {View} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Paragraph, Icon} from "../../lib/foundations/ui";
import colors from "@ch/foundations/ui/colors.json";

export default function TestingStatsInfo() {
  return (
    <View style={[tailwind("overflow-hidden")]}>
      <View style={tailwind("flex-row")}>
        <Icon name="labs" size={24} color={colors.gray[400]} />
        <View style={tailwind("ml-5 flex-1")} accessibilityLiveRegion="polite">
          <View style={tailwind("flex-row")}>
            <Paragraph level={2} style={[tailwind("text-gray-400 flex-wrap"), {width: 60}]}>
              Positive
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4 flex-1"), {color: "#000000"}]}>
              Members who have tested positive for Covid-19 in the past 10 days
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 60}]}>
              Negative
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4 flex-1"), {color: "#000000"}]}>
              Members who have tested negative for Covid-19 in the past 10 days
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 60}]}>
              Total
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4 flex-1"), {color: "#000000"}]}>
              Members who have taken a Covid-19 test in the past 10 days,{" "}
              <Paragraph level={2} weight="bold">
                including pending results
              </Paragraph>
            </Paragraph>
          </View>
        </View>
      </View>
    </View>
  );
}
