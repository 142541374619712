import React, {FC} from "react";
import {
  View,
  Platform,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  GestureResponderEvent,
  Text,
} from "react-native";

import {LinkProps as NextLinkProps} from "next/link";

export type LinkProps = {
  href: string;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  onPress?: (event: GestureResponderEvent) => void;
  accessibilityLabel: string;
  external?: boolean;
};

let NextLink: FC<NextLinkProps>;
if (Platform.OS === "web") {
  // eslint-disable-next-line
  NextLink = require("next/link").default;
}

export function Link(props: LinkProps) {
  if (Platform.OS === "web") {
    return (
      <NextLink href={props.href} passHref>
        <Text
          accessibilityRole="link"
          accessibilityLabel={props.accessibilityLabel}
          href={props.href}
          hrefAttrs={
            props.external
              ? {
                  target: "_blank",
                }
              : undefined
          }
          style={{textDecorationLine: "none"}}
        >
          <View style={props.style}>{props.children}</View>
        </Text>
      </NextLink>
    );
  }
  return (
    <TouchableOpacity style={props.style} onPress={props.onPress}>
      {props.children}
    </TouchableOpacity>
  );
}
