import {
  Paragraph,
  Tile,
  ProgressCircle,
  SkeletonBox,
  LoadingSpinner,
  CarbonModal,
  Icon,
  Subtitle,
} from "@ch/foundations/ui";
import {StyleProp, View, ViewStyle, TouchableOpacity} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import Colors from "@ch/foundations/ui/colors.json";
import {useQuery, gql} from "@apollo/client";
import {TestingStatsQuery, TestingStatsQueryVariables} from "./__generated__/TestingStatsTiletypes";
import TestingStatsInfo from "./TestingStatsInfo";
import {useState} from "react";

type TestingStatsProps = {
  style?: StyleProp<ViewStyle>;
};

export const TESTING_STATS_QUERY = gql`
  query TestingStats {
    me {
      ... on OrganizationAdminUser {
        organization {
          id
          covidTestingStats {
            membersTestedCount
            positiveTestCount
            negativeTestCount
          }
        }
      }
    }
  }
`;

export default function TestingStatsTile(props: TestingStatsProps) {
  const {loading, data, error} = useQuery<TestingStatsQuery, TestingStatsQueryVariables>(
    TESTING_STATS_QUERY,
  );

  const [modalVisible, setModalVisible] = useState(false);

  if (loading)
    return (
      <Tile style={[tailwind("p-6 flex-col"), {minWidth: "224px"}, props.style]}>
        <View style={tailwind("w-full")}>
          {/* Top section */}
          <View style={tailwind("flex-row justify-between")}>
            {/* Top Left */}
            <View>
              <Paragraph>COVID-19 Testing</Paragraph>
              <SkeletonBox style={[tailwind("mt-2"), {width: 223, height: 20}]} />
            </View>
          </View>
        </View>
        <View style={[tailwind("w-full justify-center items-center"), {height: 228}]}>
          <LoadingSpinner />
        </View>
      </Tile>
    );

  // if there is an error, just don't render the tile. We don't need to break
  // the whole page for this
  if (error || !data || !data.me) {
    return null;
  }

  switch (data.me.__typename) {
    case "OrganizationAdminUser": {
      const {covidTestingStats} = data.me.organization;
      // If we don't have this, just don't render the tile at all.
      if (!covidTestingStats) return null;

      const vizData = [
        {
          label: "Positive Tests",
          value: covidTestingStats.positiveTestCount,
          color: Colors.red.dark,
        },
        {
          label: "Negative Tests",
          value: covidTestingStats.negativeTestCount,
          color: Colors.green.light,
        },
      ];

      return (
        <>
          {/* Info modal -- closed by default */}
          <CarbonModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            headerTitle="COVID-19 Testing Status"
          >
            <TestingStatsInfo />
          </CarbonModal>

          <Tile style={[tailwind("p-6 flex-col"), {minWidth: "224px"}, props.style]}>
            <View style={tailwind("w-full h-full")}>
              {/* Top section */}
              <View style={tailwind("flex-row justify-between")}>
                {/* Top Left */}
                <View>
                  <Paragraph>COVID-19 Testing</Paragraph>
                  <Paragraph level={2} style={tailwind("mt-2 text-gray-400")}>
                    In the last 10 days,{" "}
                    <Paragraph level={2} weight="bold" style={tailwind("text-gray-400")}>
                      {covidTestingStats.membersTestedCount}
                    </Paragraph>{" "}
                    enrolled members have taken tests.
                  </Paragraph>
                </View>
                {/* Top Right */}
                <TouchableOpacity
                  testID="open-tests-info"
                  accessibilityRole="button"
                  accessibilityLabel="info about vaccination stats"
                  onPress={() => {
                    setModalVisible(true);
                  }}
                  style={tailwind("self-start")}
                >
                  <Icon name="info" size={20} color={Colors.gray[200]} />
                </TouchableOpacity>
              </View>
              {/* Bottom (content) section */}
              <View style={tailwind("flex-row justify-center items-center flex-1")}>
                {vizData.map(({label, value, color}) => {
                  return (
                    <View
                      key={`${label}:${value}`}
                      style={tailwind("justify-center items-center w-1/3")}
                    >
                      <ProgressCircle
                        label={label}
                        size={88}
                        maxValue={covidTestingStats.membersTestedCount}
                        value={value}
                        color={color}
                        secondaryColor={Colors.gray[100]}
                        pathWidth={9}
                      >
                        <Subtitle weight="bold">{value}</Subtitle>
                      </ProgressCircle>
                      <Paragraph level={2} style={tailwind("mt-4")}>
                        {label}
                      </Paragraph>
                    </View>
                  );
                })}
              </View>
            </View>
          </Tile>
        </>
      );
    }
    case "AuthenticationError": {
      throw new Error("AuthenticationError");
    }
  }
  return null;
}
