import {View} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Paragraph, Rule, Icon} from "../../lib/foundations/ui";
import colors from "@ch/foundations/ui/colors.json";
export default function VaccineStatsInfo() {
  return (
    <View style={[tailwind("w-full")]}>
      <View style={tailwind("flex-row")}>
        <Icon name="shot" size={24} color={colors.gray[400]} />
        <View style={tailwind("ml-5")} accessibilityLiveRegion="polite">
          <View style={tailwind("flex-row")}>
            <Paragraph level={2} style={[tailwind("text-gray-400 flex-wrap"), {width: 145}]}>
              Partially Vaccinated
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              1 Moderna or Pfizer vaccine
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 145}]}>
              Fully Vaccinated
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              2 Moderna / Pfizer vaccines, or 1 Johnson & Johnson
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 145}]}>
              Vaccinated + Booster
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              Fully Vaccinated with an extra vaccine dose
            </Paragraph>
          </View>
        </View>
      </View>
      <Rule style={tailwind("my-6")} />
      <View style={tailwind("flex-row")}>
        <Icon name="no" size={24} color={colors.gray[400]} />
        <View style={tailwind("ml-5")} accessibilityLiveRegion="polite">
          <View style={tailwind("flex-row")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 145}]}>
              Not Vaccinated
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              Has not received a vaccine
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 145}]}>
              Medical Exemption
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              Has proof of a medical exemption
            </Paragraph>
          </View>
          <View style={tailwind("flex-row mt-4")}>
            <Paragraph level={2} style={[tailwind("text-gray-400"), {width: 145}]}>
              Religious Exemption
            </Paragraph>
            <Paragraph level={2} style={[tailwind("ml-4"), {color: "#000000"}]}>
              Has proof of a religious exemption
            </Paragraph>
          </View>
        </View>
      </View>
    </View>
  );
}
