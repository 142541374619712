import * as React from "react";
import {StyleProp, View, ViewStyle} from "react-native";
import {tailwind} from "../styles";
type props = {
  style?: StyleProp<ViewStyle>;
  width?: number;
  height?: number;
};
export default function SkeletonBox({style, width, height}: props) {
  return <View style={[tailwind("bg-gray-100"), {width, height}, style]} />;
}
