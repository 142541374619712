import React, {ReactNode} from "react";
import {View, StyleProp, ViewStyle} from "react-native";
import {Svg, Path, G} from "react-native-svg";
type Props = {
  secondaryColor?: string;
  backgroundPathWidth?: number;
  children?: ReactNode;
  childrenContainerStyle?: StyleProp<ViewStyle>;
  color?: string;
  lineCap?: "butt" | "square" | "round";
  padding?: number;
  pathWidth: number;
  maxValue: number;
  value?: number;
  size: number;
  style?: StyleProp<ViewStyle>;
  // what is this progress bar representing -- for accessibility
  label: string;
};
function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
function makeCirclePath(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) {
  const start = polarToCartesian(x, y, radius, endAngle * 0.9999);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y];
  return d.join(" ");
}
const limitPercentage = (fill: number) => Math.min(100, Math.max(0, fill));
export default function ProgressCircle({
  style,
  size,
  maxValue,
  value,
  pathWidth,
  backgroundPathWidth,
  color = "black",
  secondaryColor,
  lineCap = "round",
  children,
  childrenContainerStyle,
  padding = 0,
  label,
}: Props) {
  const maxWidthCircle = backgroundPathWidth ? Math.max(pathWidth, backgroundPathWidth) : pathWidth;
  const sizeWithPadding = size / 2 + padding / 2;
  const radius = size / 2 - maxWidthCircle / 2 - padding / 2;
  // If there's a value at all, we should show something nonzero instead of
  // rounding down to 0.
  const progressPercentage = value
    ? Math.max(0.001, Math.round(((value || 0) / maxValue) * 100))
    : 0;

  // If the value is less than the maxValue (< 100%), leave space for rounded
  // linecaps not to merge
  const currentFillAngle =
    lineCap === "round" && value && value < maxValue
      ? Math.min(349, (360 * limitPercentage(progressPercentage)) / 100)
      : (360 * limitPercentage(progressPercentage)) / 100;
  const backgroundPath = makeCirclePath(sizeWithPadding, sizeWithPadding, radius, 0, 360);
  const foregroundPath = makeCirclePath(
    sizeWithPadding,
    sizeWithPadding,
    radius,
    0,
    currentFillAngle,
  );
  const offset = size - maxWidthCircle * 2;
  const localChildrenContainerStyle: ViewStyle = {
    position: "absolute",
    left: maxWidthCircle + padding / 2,
    top: maxWidthCircle + padding / 2,
    width: offset,
    height: offset,
    borderRadius: offset / 2,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  return (
    <View
      style={style}
      // puts the progress bar role on the actual segment, not the parent,
      // since there may be multiple segments. progressbar role from
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role
      accessibilityRole="progressbar"
      accessibilityValue={{min: 0, max: maxValue, now: value}}
      accessibilityLabel={label}
    >
      <Svg width={size + padding} height={size + padding}>
        <G rotation={90} originX={(size + padding) / 2} originY={(size + padding) / 2}>
          {secondaryColor ? (
            <Path
              d={backgroundPath}
              stroke={secondaryColor}
              strokeWidth={backgroundPathWidth || pathWidth}
              strokeLinecap={lineCap}
              strokeDasharray={[0, 0]}
              fill="transparent"
            />
          ) : null}
          {progressPercentage > 0 && (
            <Path
              d={foregroundPath}
              stroke={color}
              strokeWidth={pathWidth}
              strokeLinecap={lineCap}
              strokeDasharray={[0, 0]}
              fill="transparent"
            />
          )}
        </G>
      </Svg>
      {children ? (
        <View style={[localChildrenContainerStyle, childrenContainerStyle]}>{children}</View>
      ) : null}
    </View>
  );
}
