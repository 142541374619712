import React, {FC} from "react";
import {Platform} from "react-native";
import {ImageProps as NextImageProps} from "next/image";
export type ImageProps = Pick<
  NextImageProps,
  "src" | "alt" | "height" | "width" | "objectFit" | "layout"
>;

let NextImage: FC<ImageProps>;
if (Platform.OS === "web") {
  // eslint-disable-next-line
  NextImage = require("next/image").default;
}

export function Image(props: ImageProps) {
  if (Platform.OS === "web") {
    return <NextImage {...props} />;
  }

  throw new Error("Native Image component has not been mapped to next image");
}
