import React from "react";
import {StyleProp, ViewStyle, View} from "react-native";
import {ColorValue} from "react-native";
import {tailwind} from "../styles";

type Segment = {
  color: ColorValue;
  value: number;
  label: string;
};
type ProgressBarProps = {
  testID?: string;
  style?: StyleProp<ViewStyle>;
  segments: Array<Segment>;
  maxValue: number;
};

export default function ProgressBar({style, segments, testID, maxValue}: ProgressBarProps) {
  return (
    <View
      testID={testID || "progress"}
      style={[tailwind("bg-gray-100 rounded-full overflow-hidden"), {height: 10}, style]}
    >
      {segments
        // need to be sorted so the longer bars don't overlap the shorter ones
        .sort((a, b) => b.value - a.value)
        .map(({color, value, label}, i) => (
          <View
            key={`${i}${value}`}
            style={[
              tailwind("h-full absolute left-0 top-0 bottom-0 rounded-full"),
              {backgroundColor: color, width: `${(value / maxValue) * 100}%`},
            ]}
            // puts the progress bar role on the actual segment, not the parent,
            // since there may be multiple segments. progressbar role from
            // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role
            accessibilityRole="progressbar"
            accessibilityValue={{min: 0, max: maxValue, now: value}}
            accessibilityLabel={label}
          />
        ))}
    </View>
  );
}
