import {Tile} from "@ch/foundations/ui";
import {Image} from "../../lib/Image";
import {StyleProp, View, ViewStyle} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Caption, Heading, LoadingSpinner} from "@ch/foundations/ui";

type LoadingProps = {loading: true; style?: StyleProp<ViewStyle>};
type LoadedProps = {
  loading?: false;
  style?: StyleProp<ViewStyle>;
  image: string;
  heading: string;
  value: string;
};
type BasicStatTileProps = LoadingProps | LoadedProps;

export default function BasicStatTile(props: BasicStatTileProps) {
  if (props.loading === true) {
    return (
      <Tile style={props.style}>
        <View style={tailwind("h-32 w-full justify-center items-center bg-gray-50")}>
          <LoadingSpinner size={24} />
        </View>
      </Tile>
    );
  } else {
    return (
      <Tile style={[tailwind("h-32 p-1"), {minWidth: "224px"}, props.style]}>
        {!!props.image && (
          <Image
            src={`/static/images/${props.image}.png`}
            alt={props.image}
            width={120}
            height={120}
            objectFit="contain"
          />
        )}
        <View style={tailwind("ml-4 h-16 justify-between flex-1")}>
          <Caption style={tailwind("text-gray-400")}>{props.heading}</Caption>
          <Heading level={5} weight="bold" accessibilityRole="label">
            {props.value}
          </Heading>
        </View>
      </Tile>
    );
  }
}
